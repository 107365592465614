import React, { useState, useEffect, useCallback } from "react";
import styles from './Matches.module.scss';
// import { API, Storage } from 'aws-amplify';
// import { withAuthenticator, AmplifySignOut } from "@aws-amplify/ui-react";
// import { listNotes } from './graphql/queries';
// import { createNote as createNoteMutation, deleteNote as deleteNoteMutation } from './graphql/mutations';
import {Table,Container,Row,Col,Button,Toast} from "react-bootstrap"
import PlayerPop from '../components/PlayerPop'
import GoogleAd from '../components/GoogleAd'
import SnackAd from '../components/SnackAd'


const axios = require("axios");

function GwInfo(props) {

  const gameWeek = props.gwLeague>0 ? ("Gameweek " + props.gwLeague) : null

  return(
    <Container fluid className ={styles.gwInfo}>
      <Row>
        <Col>
          <h1 className ={styles.gameweekh1}>
            {gameWeek}
          </h1>
        </Col>
      </Row>
    </Container>
  )
}


function PlayerStats(props) {

  if(props.fplStats.length==0){
    return null
  }
  else{

    const eo_style_head = props.rankOrd===1 ? "bg-success" : "bg-default";
    const own_style_head = props.rankOrd===2 ? "bg-success" : "bg-default";
    const cap_style_head = props.rankOrd===3 ? "bg-success" : "bg-default";
    const vcap_style_head = props.rankOrd===4 ? "bg-success" : "bg-default";

    const eo_style = props.rankOrd===1 ? "table-success" : "table-default";
    const own_style = props.rankOrd===2 ? "table-success" : "table-default";
    const cap_style = props.rankOrd===3 ? "table-success" : "table-default";
    const vcap_style = props.rankOrd===4 ? "table-success" : "table-default";

  return (
    <Table
      key={props.fid + props.type}
      size="sm"
      className={styles.players_table + " shadow"}
    >
      <thead className="table-dark">
        <tr>
          <th className ={"d-none d-sm-table-cell"}>Rank</th>
          <th style={{textAlign:"left"}}>Player</th>
          <th className ={"d-none d-sm-table-cell"}>Team</th>
          <th className ={"d-none d-sm-table-cell"}>Pos</th>
          <th className={eo_style_head} onClick={()=>props.setUrl(props.newUrl(1))} style={{cursor:"pointer"}}>EO%</th>
          <th className={own_style_head} onClick={()=>props.setUrl(props.newUrl(2))} style={{cursor:"pointer"}}>Owned%</th>
          <th className={cap_style_head} onClick={()=>props.setUrl(props.newUrl(3))} style={{cursor:"pointer"}}>Cap%</th>
          <th className={vcap_style_head} onClick={()=>props.setUrl(props.newUrl(4))} style={{cursor:"pointer"}}>VCap%</th>
        </tr>
      </thead>
      <tbody>
        {props.fplStats.map(plyr => (
          <tr key={plyr.rank} style={{padding:"0"}}>
           <td className ={"d-none d-sm-table-cell"}>{plyr.rank}</td>
            <td
              style={{textAlign:"left"}}
            >
              {plyr.pl_name}
            </td>
            <td className ={"d-none d-sm-table-cell"}>{plyr.team}</td>
            <td className ={"d-none d-sm-table-cell"}>
              {plyr.pos}
            </td>
            <td className={eo_style}>
              {plyr.eo_per}
            </td>
            <td className={own_style}>
              {plyr.own_per}
            </td>
            <td className={cap_style}>
              {plyr.cap_per}
            </td>
            <td className={vcap_style}>
              {plyr.vcap_per}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
}

// const live_url = 'https://o8bbxwfg8k.execute-api.eu-west-1.amazonaws.com/dev/api/fpl_alerts_aws_live_19';
const live_url = 'https://o8bbxwfg8k.execute-api.eu-west-1.amazonaws.com/dev/api/stats/1';

const useMatchesState = () => {
  // const [gw, setGW] = useState(0);
  const [url, setUrl] = useState(live_url)
  const [responseData, setResponseData] = useState([]);

  const newUrl = (ord) => {
    if(ord === 1){
      return 'https://o8bbxwfg8k.execute-api.eu-west-1.amazonaws.com/dev/api/stats/1';
    }
      else {
        return 'https://o8bbxwfg8k.execute-api.eu-west-1.amazonaws.com/dev/api/stats/'+ord;
      }
    }
  var fplstats = [];
  var gwLeague = 0;
  var rankOrd =1;

  if (Object.keys(responseData).length > 0) {

    fplstats = responseData.stats;
    gwLeague = responseData.summary.gw_league;
    rankOrd = responseData.summary.rank_ord;

  }

  const fetchFpl = async (url) => {

    console.log('fetch_url:'+url)

    const response = await axios.get(url);
    setResponseData(response.data);
    console.log("fetchran");

  };

  useEffect(() => {
    fetchFpl(url);
    // setGW(responseData.data.status.gw);
    console.log("useeffect ran");
    const intervalId = setInterval(() => {  //assign interval to a variaable to clear it
      fetchFpl(url);
      console.log("useeffect ran again");
    }, 600000)

    return () => {
      clearInterval(intervalId); //This is important
      console.log("clearInterval");
    }

  }, [url])
  // },)

return {gwLeague, fplstats, setUrl, newUrl,rankOrd};
}

const Stats = () => {

  const {
    gwLeague,
    fplstats,
    setUrl,
    newUrl,
    rankOrd,
  } = useMatchesState();

  return (
    <Container fluid >
      <Row>
        <Col xs={{span:12, order:2}} lg={{span:3, order:1}} style={{ marginLeft: 0 }}>
        <SnackAd ad_type = "skyscraper" ad_num = {1}/>
        </Col>
        <Col xs={{span:12, order:1}} lg={{span:6, order:2}} className={styles.App2}>
          <GwInfo gwLeague={gwLeague}/>
          <Container fluid className={styles.match_all + " shadow-lg"} style={{padding:"20px"}}>
            <div>
              <h4 className={styles.stats_title}>
              Top 10k Stats
              </h4>
            </div>
            <PlayerStats fplStats ={fplstats} setUrl={setUrl} newUrl = {newUrl} rankOrd={rankOrd} style={{padding:"20"}}/>
          </Container>
        </Col>
        <Col xs={{span:12, order:3}} lg={{span:3, order:3}} style={{ marginRight: 0 }}>
        <SnackAd ad_type = "skyscraper" ad_num = {2}/>
        </Col>
      </Row>
    </Container>
  );
}

export default Stats;
